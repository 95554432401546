import React from "react";
import Image from "next/image";
import { CopyBlock } from "react-code-blocks";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styles from "./RichText.module.css";
import Link from "next/link";

const RichTextView = ({
  heading,
  subHeading,
  content,
  headingAlignment,
  codeLanguage,
}) => {
  return (
    <div
      className={styles["rich-text-root"]}
      style={{ textAlign: headingAlignment, alignItems: headingAlignment }}
    >
      {heading && (
        <h2 className={styles["title"]} style={{ textAlign: headingAlignment }}>
          {heading}
        </h2>
      )}
      {subHeading && (
        <div
          className={styles["sub-heading"]}
          style={{ textAlign: headingAlignment }}
        >
          {subHeading}
        </div>
      )}
      <BlocksRenderer
        content={content}
        blocks={{
          heading: ({ level, children }) => {
            if (level === 1) {
              return <h1 className={styles["h1"]}>{children}</h1>;
            }
            if (level === 2) {
              return <h2 className={styles["h2"]}>{children}</h2>;
            }
            if (level === 3) {
              return <h3 className={styles["h3"]}>{children}</h3>;
            }
          },
          image: ({ image }) => {
            return (
              <Image
                src={image.url}
                width={image.width}
                height={image.height}
                alt={image.alternativeText || ""}
              />
            );
          },
          paragraph: ({ children }) => {
            return <p className={styles["paragraph"]}>{children}</p>;
          },
          list: ({ format, children }) => {
            if (format === "ordered") {
              return <ol className={styles["ol"]}>{children}</ol>;
            } else {
              return <ul className={styles["ul"]}>{children}</ul>;
            }
          },
          link: ({ children, url }) => (
            <Link className={styles["link"]} href={url}>
              {children}
            </Link>
          ),
          code: ({ children }) => (
            <div className={styles["code"]}>
              <CopyBlock
                text={children[0]?.props?.text}
                language={codeLanguage}
                showLineNumbers={true}
                codeBlock={true}
                theme="obsidian"
              />
            </div>
          ),
        }}
      />
    </div>
  );
};

export default RichTextView;
